const scrollTo = (target, bottom = false) => {
  let targetPosition = target.offsetTop;

  if (bottom) {
    targetPosition = target.offsetTop + target.offsetHeight - window.innerHeight;
  }

  window.scroll({
    top: targetPosition,
    left: 0,
    behavior: 'smooth',
  });
};

const addScrollLinkClickHandler = () => {
  document.addEventListener('click', (evt) => {
    const $target = evt.target;
    const $scrollLink = $target.closest('.js-scroll-link') || false;

    if ($scrollLink) {
      evt.preventDefault();
      $target.blur();
      const linkHref = $scrollLink.getAttribute('href');
      const linkDataset = $scrollLink.dataset || false;
      const $scrollTargetNode = linkHref && linkHref !== '#' ? document.querySelector(`${linkHref}`) || false : false;
      let isScrollBottom = false;

      if (linkDataset) {
        isScrollBottom = linkDataset.bottom === 'true';
      }

      if ($scrollTargetNode) {
        scrollTo($scrollTargetNode, isScrollBottom);
      }
    }
  });
};

export {addScrollLinkClickHandler, scrollTo};
