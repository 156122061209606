import {openModal, closeModal} from '../utils/modal';
import {enableScrolling} from '../utils/scroll-lock';

let cookiewin = document.querySelector('.modal--feedback');

const onButtonClick = () => {
  closeModal(cookiewin);
};

const onEscKeyDown = (evt) => {
  if (evt.key === 'Escape' || evt.key === 'Esc') {
    closeModal(cookiewin);
    document.removeEventListener('keydown', onEscKeyDown);
  }
};

const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const showCookie = () => {
  let cookiecook = getCookie('cookiecook');
  if (cookiecook !== 'no') {
    if (cookiewin) {
      openModal(cookiewin);
      enableScrolling();

      const btnClose = cookiewin.querySelector('.modal__close-btn');
      const btnModal = cookiewin.querySelector('.cookie-message__button');

      btnClose.addEventListener('click', onButtonClick);
      btnModal.addEventListener('click', onButtonClick);
      document.addEventListener('keydown', onEscKeyDown);

      let date = new Date();
      date.setDate(date.getDate() + 1);
      document.cookie = 'cookiecook=no; path=/; expires=' + date.toUTCString();
    }
  }
};

export {showCookie};
