import {setupModal} from '../utils/modal';

const modals = document.querySelectorAll('.modal');
const modalFeedback = document.querySelector('.modal--feedback');
const modalFeedbackBtns = document.querySelectorAll('[data-modal="feedback"]');
const modalSuccess = document.querySelector('.modal--success');
const modalSuccessBtns = document.querySelectorAll('[data-modal="success"]');
const modalMethodology = document.querySelector('.modal--methodology');
const modalMethodologyBtns = document.querySelectorAll('[data-modal="methodology"]');
const modalTooltipNaumova = document.querySelector('.modal--tooltip-naumova');
const modalTooltipNaumovaBtns = document.querySelectorAll('[data-modal="tooltip-naumova"]');
const modalTooltipKitsos = document.querySelector('.modal--tooltip-kitsos');
const modalTooltipKitsosBtns = document.querySelectorAll('[data-modal="tooltip-kitsos"]');
const modalTooltipDelugo = document.querySelector('.modal--tooltip-delugo');
const modalTooltipDelugoBtns = document.querySelectorAll('[data-modal="tooltip-delugo"]');
const modalTooltipFernandez = document.querySelector('.modal--tooltip-fernandez');
const modalTooltipFernandezBtns = document.querySelectorAll('[data-modal="tooltip-fernandez"]');
const modalTooltipKayal = document.querySelector('.modal--tooltip-kayal');
const modalTooltipKayalBtns = document.querySelectorAll('[data-modal="tooltip-kayal"]');
const modalTooltipHatcher = document.querySelector('.modal--tooltip-hatcher');
const modalTooltipHatcherBtns = document.querySelectorAll('[data-modal="tooltip-hatcher"]');
const modalTooltipShishkova = document.querySelector('.modal--tooltip-shishkova');
const modalTooltipShishkovaBtns = document.querySelectorAll('[data-modal="tooltip-shishkova"]');
const modalTooltipShabab = document.querySelector('.modal--tooltip-shabab');
const modalTooltipShababBtns = document.querySelectorAll('[data-modal="tooltip-shabab"]');

// аргументы setupModal(modal, closeCallback, modalBtns, openCallback, noPrevDefault, preventScrollLock)
// возможна инициализация только с первыми аргументом,
// если вам нужно открывать модалку в другом месте под какими-нибудь условиями
const initModals = () => {
  // фикс для редких случаев, когда модалка появляется при загрузке страницы
  window.addEventListener('load', () => {
    if (modals.length) {
      modals.forEach((el) => {
        setTimeout(() => {
          el.classList.remove('modal--preload');
        }, 100);
      });
    }
  });

  if (modalFeedback && modalFeedbackBtns.length) {
    setupModal(modalFeedback, false, modalFeedbackBtns, false, false);
  }
  if (modalSuccess && modalSuccessBtns.length) {
    setupModal(modalSuccess, false, modalSuccessBtns);
  }
  if (modalMethodology && modalMethodologyBtns.length) {
    setupModal(modalMethodology, false, modalMethodologyBtns);
  }
  if (modalTooltipNaumova && modalTooltipNaumovaBtns.length) {
    setupModal(modalTooltipNaumova, false, modalTooltipNaumovaBtns);
  }
  if (modalTooltipKitsos && modalTooltipKitsosBtns.length) {
    setupModal(modalTooltipKitsos, false, modalTooltipKitsosBtns);
  }
  if (modalTooltipDelugo && modalTooltipDelugoBtns.length) {
    setupModal(modalTooltipDelugo, false, modalTooltipDelugoBtns);
  }
  if (modalTooltipFernandez && modalTooltipFernandezBtns.length) {
    setupModal(modalTooltipFernandez, false, modalTooltipFernandezBtns);
  }
  if (modalTooltipKayal && modalTooltipKayalBtns.length) {
    setupModal(modalTooltipKayal, false, modalTooltipKayalBtns);
  }
  if (modalTooltipHatcher && modalTooltipHatcherBtns.length) {
    setupModal(modalTooltipHatcher, false, modalTooltipHatcherBtns);
  }
  if (modalTooltipShishkova && modalTooltipShishkovaBtns.length) {
    setupModal(modalTooltipShishkova, false, modalTooltipShishkovaBtns);
  }
  if (modalTooltipShabab && modalTooltipShababBtns.length) {
    setupModal(modalTooltipShabab, false, modalTooltipShababBtns);
  }
};

export {initModals};
