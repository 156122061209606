const menu = () => {
  const mainNav = document.querySelector('.main-nav');
  const menuBtn = document.querySelector('.button-menu');

  const onMenuBtnClick = function (evt) {
    evt.preventDefault();
    mainNav.classList.toggle('main-nav--modal');
    menuBtn.classList.toggle('button-menu--close');
    document.body.classList.toggle('overflow-hidden');
  };

  menuBtn.addEventListener('click', onMenuBtnClick);
};

export {menu};
