import {showButtonName} from './button-name.js';
const mediaSize = 768;
const swiperContainer = document.querySelector('.women');
let swiper = false;

const createSwiper = () => {
  swiper = new Swiper('.women', {
    pagination: {
      el: '.women__pagination',
      clickable: true,
      type: 'bullets',
    },
  });
};

const destroySwiper = () => {
  if (swiper) {
    swiper.destroy();
    swiper = null;
  }
};

const initSwiperPhoto = () => {
  if (swiperContainer) {
    if (document.body.clientWidth < mediaSize && !swiper) {
      createSwiper();
      showButtonName(swiper);
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth >= mediaSize) {
        destroySwiper();
      } else {
        if (swiper) {
          setTimeout(() => {
            swiper.update();
          }, 100);
        } else {
          createSwiper();
          showButtonName(swiper);
        }
      }
    });
  }
};

export {initSwiperPhoto};
