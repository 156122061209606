const initSwiperPartners = () => new Swiper('.partners__container', {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  navigation: {
    nextEl: '.partners__btn--next',
    prevEl: '.partners__btn--prev',
  },
});

export {initSwiperPartners};
