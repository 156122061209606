import {scrollTo} from "./scroll-link";

const btnGirl1 = document.querySelector('[data-button="button-girl-1"]');
const btnGirl2 = document.querySelector('[data-button="button-girl-2"]');
const btnGirl3 = document.querySelector('[data-button="button-girl-3"]');
const btnGirl4 = document.querySelector('[data-button="button-girl-4"]');
const btnGirl5 = document.querySelector('[data-button="button-girl-5"]');

const cardGirl1 = document.querySelector('[data-region="The Asia-Pacific"]');
const cardGirl2 = document.querySelector('[data-region="Global"]');
const cardGirl3 = document.querySelector('[data-region="META*"]');
const cardGirl4 = document.querySelector('[data-region="Europe"]');
const cardGirl5 = document.querySelector('[data-region="Russia"]');

document.querySelector('[data-region="The Asia-Pacific"]');

const regionContainer = document.querySelector('.regions') || false;

const scroll = (button, card, swiper, index) => {
  if (!regionContainer) {
    return;
  }
  button.addEventListener('click', () => {
    swiper.update();
    swiper.slideTo(index);
    setTimeout(() => {
      scrollTo(regionContainer, true);
    }, 10);
  });
};

const scrollToRegions = (regionsSlider) => {
  if (btnGirl1 && cardGirl1) {
    scroll(btnGirl1, cardGirl1, regionsSlider, 0);
  }
  if (btnGirl2 && cardGirl2) {
    scroll(btnGirl2, cardGirl2, regionsSlider, 1);
  }
  if (btnGirl3 && cardGirl3) {
    scroll(btnGirl3, cardGirl3, regionsSlider, 4);
  }
  if (btnGirl4 && cardGirl4) {
    scroll(btnGirl4, cardGirl4, regionsSlider, 5);
  }
  if (btnGirl5 && cardGirl5) {
    scroll(btnGirl5, cardGirl5, regionsSlider, 6);
  }
};


export {btnGirl1, btnGirl2, btnGirl3, btnGirl4, btnGirl5, scrollToRegions};
