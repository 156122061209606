import tippy from 'tippy.js';

const showModalComment = () => {

  const commentGirl1 = document.querySelector('#template-girl-1');
  const commentGirl2 = document.querySelector('#template-girl-2');
  const commentGirl3 = document.querySelector('#template-girl-3');
  const commentGirl4 = document.querySelector('#template-girl-4');
  const commentGirl5 = document.querySelector('#template-girl-5');


  if (commentGirl1) {
    tippy('#comment-girl-1', {
      content: commentGirl1.innerHTML,
      allowHTML: true,
      interactive: true,
      theme: 'light',
      arrow: false,
      placement: 'top',
      zIndex: 9999,
      popperOptions: {
        placement: 'top',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top'],
            },
          }
        ],
      },
    });
  }

  if (commentGirl2) {
    tippy('#comment-girl-2', {
      content: commentGirl2.innerHTML,
      allowHTML: true,
      interactive: true,
      theme: 'light',
      arrow: false,
      placement: 'top',
      zIndex: 9999,
      popperOptions: {
        placement: 'top',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top'],
            },
          }
        ],
      },
    });
  }

  if (commentGirl3) {
    tippy('#comment-girl-3', {
      content: commentGirl3.innerHTML,
      allowHTML: true,
      interactive: true,
      theme: 'light',
      arrow: false,
      placement: 'top',
      zIndex: 9999,
      popperOptions: {
        placement: 'top',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top'],
            },
          }
        ],
      },
    });
  }

  if (commentGirl4) {
    tippy('#comment-girl-4', {
      content: commentGirl4.innerHTML,
      allowHTML: true,
      interactive: true,
      theme: 'light',
      arrow: false,
      placement: 'top',
      zIndex: 9999,
      popperOptions: {
        placement: 'top',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top'],
            },
          }
        ],
      },
    });
  }

  if (commentGirl5) {
    tippy('#comment-girl-5', {
      content: commentGirl5.innerHTML,
      allowHTML: true,
      interactive: true,
      theme: 'light',
      arrow: false,
      placement: 'top',
      zIndex: 9999,
      popperOptions: {
        placement: 'top',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top'],
            },
          }
        ],
      },
    });
  }
};

export {showModalComment};
