const initWorkersSlider = () => {
  const workersSlider = document.querySelector('.employees');
  const cardEmployeeLink = document.querySelectorAll('.card-employee__card-link');

  for (let i = 0; i < cardEmployeeLink.length; i++) {
    const link = cardEmployeeLink[i].getAttribute('href');
    if (!link) {
      cardEmployeeLink[i].parentElement.classList.add('card-employee--opacity');
    }
  }

  if (workersSlider) {
    const workersSwiper = new Swiper('.employees__wrapper', {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: '.employees__pagination',
        clickable: true,
        type: 'bullets',
      },
      navigation: {
        nextEl: '.employees__button--next',
        prevEl: '.employees__button--prev',
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },

        768: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        1440: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 20,
        },
      },
    });
    return workersSwiper;
  }
};

export {initWorkersSlider};
