import {btnGirl1, btnGirl2, btnGirl3, btnGirl4, btnGirl5} from './scroll';

const mediaSize = 768;

const showButton = (button) => {
  button.style.display = 'block';
};

const changeCurrentButton = (button) => {
  currentButton.style.display = 'none';
  currentButton = button;
  showButton(button);
};

let currentButton = btnGirl1;

const update = (swiper) => {
  if (swiper.activeIndex === 0) {
    changeCurrentButton(btnGirl1);
  }
  if (swiper.activeIndex === 1) {
    changeCurrentButton(btnGirl4);
  }
  if (swiper.activeIndex === 2) {
    changeCurrentButton(btnGirl3);
  }
  if (swiper.activeIndex === 3) {
    changeCurrentButton(btnGirl2);
  }
  if (swiper.activeIndex === 4) {
    changeCurrentButton(btnGirl5);
  }
};

const showButtonOnSlide = (swiper) => {
  if (document.body.clientWidth < mediaSize) {
    update(swiper, changeCurrentButton);
    swiper.on('slideChangeTransitionEnd', function () {
      update(swiper, changeCurrentButton);
    });
  }
};

const showButtonName = (swiper) => {
  if (btnGirl1 && btnGirl2 && btnGirl3 && btnGirl4 && btnGirl5) {
    showButtonOnSlide(swiper);
    window.addEventListener('resize', () => {
      if (document.body.clientWidth >= mediaSize) {
        showButton(btnGirl1);
        showButton(btnGirl2);
        showButton(btnGirl3);
        showButton(btnGirl4);
        showButton(btnGirl5);
      } else {
        btnGirl2.removeAttribute('style');
        btnGirl3.removeAttribute('style');
        btnGirl4.removeAttribute('style');
        btnGirl5.removeAttribute('style');
      }
    });
  }
};

export {showButtonName};
