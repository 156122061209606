import {ieFix} from './utils/ie-fix';
import {iosVhFix} from './utils/ios-vh-fix';

import {initModals} from './modules/init-modals';
import {scrollToRegions} from './modules/scroll';
import {menu} from './modules/menu';
import {initRegionsSlider} from './modules/init-regions-slider';
import {initSwiperPartners} from './modules/swiper-partners';
import {initSwiperPhoto} from './modules/swiper-photo';
import {initWorkersSlider} from './modules/swiper-workers';
import {showCookie} from './modules/modal-feedback-cookie';
import {showModalComment} from './modules/modal-comment.js';
import {addScrollLinkClickHandler} from './modules/scroll-link';

const regionsSlider = initRegionsSlider();
// Utils
// ---------------------------------

ieFix();
iosVhFix();

// Modules
// ---------------------------------

initModals();
scrollToRegions(regionsSlider);
menu();
initSwiperPartners();
initWorkersSlider();
initSwiperPhoto();
showCookie();
showModalComment();
addScrollLinkClickHandler();
