const initRegionsSlider = () => {
  const $regionsSlider = document.querySelector('.js-regions-slider');

  if ($regionsSlider) {
    const $slides = $regionsSlider.querySelectorAll('.regions__slide');
    const regionsSlider = new Swiper($regionsSlider, {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      navigation: {
        nextEl: '.regions__arrow--next',
        prevEl: '.regions__arrow--prev',
      },
      pagination: {
        el: '.regions__slider-pagination',
        clickable: true,
        type: 'bullets',
        renderBullet: function (index, className) {
          const region = $slides[index].dataset.region;
          return `<span class=${className}><span>${region}</span></span>`;
        },
      },
    });
    return regionsSlider;
  }
};

export {initRegionsSlider};
